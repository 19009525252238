import React from 'react'
import Layout from "../components/layout"


export default () => {
  return (
    <Layout
          title="Not Found"
          description="" >

          <h2>Ooops! Wrong Turn!</h2>
    </Layout>
    )


}
